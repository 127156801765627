import React from 'react';
import cx from 'classnames';
import { Container } from '@nbcuniversal-paint/nbcu-react-components';
import Logo from '../Logo';
import styles from './Header.module.scss';

const Header = (): React.ReactElement => {
  return (
    <header className={cx('header-wrapper', styles.headerwrapper)}>
      <Container>
        <div>
          <div className={cx('logo', styles.logo)}>
            <Logo />
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
