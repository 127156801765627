import React from 'react';
import styles from './Logo.module.scss';
import { useNavigate } from 'react-router';
import Logo1 from './13thstreet/logo.svg';
import Logo2 from './syfywire/logo.svg';
import Logo3 from './diva/logo.svg';
import Logo4 from './dreamworks/logo.svg';
import Logo5 from './universal/logo.svg';
import Logo6 from './scifiwire/logo.svg';
import Logo7 from './13ulica/logo.svg';

const logos: any = {
  '13emerue': Logo1,
  '13-ulica': Logo7,
  'diva-universal': Logo3,
  dwnl: Logo4,
  'sci-fi-poland': Logo6,
  'sci-fi-serbia': Logo6,
  'sci-fi-slovenia': Logo6,
  syfy: Logo2,
  unau: Logo5,
};

const Logo = (): JSX.Element => {
  const navigate = useNavigate();

  const appName = process.env.REACT_APP_EPGNAME;

  const renderLogo = () => {
    return (
      <div>
        <img
          className={styles.logo}
          src={logos[appName!]}
          alt=""
          style={{ width: '100px', cursor: 'pointer' }}
        />
      </div>
    );
  };

  return (
    <button
      onClick={() => {
        navigate('/');
        window.location.reload();
      }}
    >
      {renderLogo()}
    </button>
  );
};

export default Logo;
